<template>
  <div class="documents-view">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'DocumentsView',
  components: {},
  beforeRouteEnter (to, from, next) {
    next(to.matched.length !== 1)
  },
  beforeRouteUpdate (to, from, next) {
    next(to.matched.length !== 1)
  }
}
</script>
